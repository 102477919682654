.formBox {
  @apply mb-5 flex items-center 2xl:mb-14;
}

.formBox label {
  @apply w-1/2 2xl:w-[28%] font-medium text-base lg:text-lg 2xl:text-2xl;
  white-space: nowrap;
}

.formBox input {
  @apply border border-[#74A8C3] rounded-md text-sm lg:text-base 2xl:text-2xl lg:h-10 2xl:h-14 2xl:border-2 2xl:rounded-[15px] h-6;
}
