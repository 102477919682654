.text-shadow {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

.physics,
.chemistry,
.maths {
  box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.timingContainer .container {
  @apply bg-[linear-gradient(to_right,_#F47B1E,_#ffffff)] shadow-xl rounded-tr-full rounded-br-full py-[14px] pl-[14px] md:bg-[linear-gradient(to_left,_#F47B1E,_#ffffff)] md:rounded-tl-full md:rounded-tr-none md:rounded-bl-full md:rounded-br-none md:py-4 md:pr-[14px] md:text-end lg:py-4 xl:py-[24px] xl:pr-[30px] mb-3 w-[90%] md:w-[55%] xl:w-[50%] text-sm md:text-base lg:text-lg xl:text-2xl font-semibold leading-[268%] xl:leading-[268%];
}

.features .feature {
  @apply bg-[linear-gradient(to_right,_#F67F00,_#FF9D34,_#FFA544,_#FFC07C)] shadow-md rounded-lg pt-5 pb-6 px-2 gap-5 flex items-center text-[#002F48] font-semibold text-[16px] xl:text-2xl md:flex-col md:text-center md:p-6 lg:text-lg lg:py-10 lg:px-10 xl:px-14 xl:pb-20 xl:pt-10 xl:gap-10;
}

.testimonialContainer .slick-dots {
  bottom: -40px;
  width: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.testimonialContainer .slick-dots {
  display: flex !important;
}

.testimonialContainer .slick-dots li {
  width: 100%;
  height: 4px;
  margin: 0;
}

.testimonialContainer .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
}

.testimonialContainer .slick-dots li button:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #d9d9d9;
  opacity: 1;
  border-radius: 2px;
}

.testimonialContainer .slick-dots li.slick-active button:before {
  background: #000;
  opacity: 1;
}
